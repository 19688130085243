html {
    height: 100vh;
    width: 100%;
}

body {
    height: 100%;
}

#root {
    height: 100%;
}

main {
    background-color: white;
    padding-top: 5rem !important;
    height: 100%;
}

.component-load{
    opacity: 1 !important;
}